import {API} from "../../axios/axiosMainUrl";
import {prepareProfileFilesArray} from "../../services/commonFunctions";
import {PERMISSIONS} from "../../staticData/permissionsStatic";

const _ = require('lodash');

export const user = {
  state: {
    id: false,
    type: 'customer',

    avatarSmall: '',
    currentUserRights: {},
    changeUserRights: false,
    loadingUserRights: false,
    userProfile: {},
    userSettings: {},

    userProfileFiles: {},
    userProfileFilesPassport: [],
    userProfileFilesCarPassport: [],

    users: [],
    userItem: {},
    usersCommonList: [],
    nextUsersPage: false,
    loadingUsers: false,
    userBtn: false,
    usersForPage: 25,
    usersFilter: '',


    profileBtn: false,

    userManageBtn: false,

    usersFromFilter: [],

    impersonateUsers: [],


    loadingUserItem: false,
    loadingAvatarSmall: false,
  },

  mutations: {
    setAvatarSmall: (state, payload) => {
      state.avatarSmall = payload
    },

    setUserType: (state, payload) => {
      state.type = payload.type
    },

    setUserProfile: (state, payload) => {
      state.userProfile = payload.userProfile
    },

    setUserSettings: (state, payload) => {
      state.userSettings = payload.userSettings
    },

    setUserProfileFiles: (state, payload) => {
      state.userProfileFiles = payload.userProfileFiles
    },

    setUserProfileFilesPassport: (state, payload) => {
      state.userProfileFilesPassport = payload.userProfileFilesPassport
    },

    setUserProfileFilesCarPassport: (state, payload) => {
      state.userProfileFilesCarPassport = payload.userProfileFilesCarPassport
    },

    setUsers: (state, payload) => {
      if(state.nextUsersPage) {
        state.users = state.users.concat(payload.users)
      } else {
        state.users = payload.users
      }
    },

    setUsersCommonList: (state, payload) => {
      state.usersCommonList = payload.usersCommonList
    },

    setUsersFromFilter: (state, payload) => {
      state.usersFromFilter = payload.usersFromFilter
    },

    setImpersonateUsers: (state, payload) => {
      state.impersonateUsers = payload.impersonateUsers
    },

    setUserItem: (state, payload) => {
      state.userItem = payload.userItem
    },

    setCurrentUserRights: (state, payload) => {
      state.currentUserRights = payload.currentUserRights
    },

    addCurrentUserRights: (state, payload) => {
      state.currentUserRights.additionalPermissions[payload.key] = payload.item
      state.changeUserRights = true
      setTimeout(() => {state.changeUserRights = false})
    },

    setLoadingUserRights: (state) => {
      state.loadingUserRights = true
    },

    setLoadingAvatarSmallStart: (state) => {
      state.loadingAvatarSmall = true
    },
    setLoadingAvatarSmallEnd: (state) => {
      state.loadingAvatarSmall = false
    },

    setLoadingStart: (state) => {
      state.loadingUsers = true
    },
    setLoadingUserItemStart: (state) => {
      state.loadingUsers = true
    },

    setLoadingEnd: (state) => {
      state.loadingUsers = false
    },
    setLoadingUserItemEnd: (state) => {
      state.loadingUsers = false
    },


    setNextUsersPage: (state, payload) => {
      state.nextUsersPage = payload
    },


    changeUser: (state) => {
      state.userBtn = true
    },
    successUser: (state) => {
      state.userBtn = false
    },

    changeProfileBtn: (state) => {
      state.profileBtn = true
    },
    successProfileBtn: (state) => {
      state.profileBtn = false
    },

    changeUserManageBtn: (state) => {
      state.userManageBtn = true
    },
    successUserManageBtn: (state) => {
      state.userManageBtn = false
    },

  },

  getters: {
    getAvatarSmall: state => {
      return state.avatarSmall
    },

    getLoadingAvatarSmall: state => {
      return state.loadingAvatarSmall
    },

    getUserType: state => {
      return state.type
    },

    getUserProfileFiles: state => {
      return state.userProfileFiles
    },

    getUserProfileFilesPassport: state => {
      return state.userProfileFilesPassport
    },

    getUserProfileFilesCarPassport: state => {
      return state.userProfileFilesCarPassport
    },

    getUserProfile: state => {
      return state.userProfile
    },

    getUserSettings: state => {
      return state.userSettings
    },

    getCurrentUserRights: state => {
      return state.currentUserRights
    },

    getChangeUserRights: state => {
      return state.changeUserRights
    },

    getLoadingUserRights: state => {
      return state.loadingUserRights
    },

    getIsAdminRights: state => {
      if(state.currentUserRights.additionalPermissions &&
        state.currentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]){
        return 'admin'
      }
      if(state.currentUserRights.additionalPermissions &&
        !state.currentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]){
        return 'user'
      }
    },

    getUsersForPage: state => {
      return state.usersForPage
    },

    getUsers: state => {
      return state.users
    },

    getUsersCommonList: state => {
      return state.usersCommonList
    },

    getUsersFromFilter: state => {
      return state.usersFromFilter
    },


    getNextUsersPage: (state) => {
      return state.nextUsersPage
    },

    getImpersonateUsers: state => {
      return state.impersonateUsers
    },

    getUserItem: state => {
      return state.userItem
    },

    getUserBtn: state => {
      return state.userBtn
    },

    getProfileBtn: state => {
      return state.profileBtn
    },

    getUsersLoading: state => {
      return state.loadingUsers
    },

    getUserManageBtn: state => {
      return state.userManageBtn
    },

  },

  actions: {
    changeUserType({commit}, type) {
      commit('setUserType',{type: type,})
    },


    async fetchCurrentUserRights({commit}) {
      commit('setLoadingUserRights');
      try {
        return await API.get(`access-controls/user-access/list`).then((response) =>{
          commit('setCurrentUserRights', {currentUserRights: response.data.data});
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async fetchPhonesData() {
      try {
        return await API.get(`users/user-profile/create`).then((response) =>{
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async updateUserPhones({commit}, {id, data}) {
      try {
        return await API.put(`users/user-profile/${id}/phone-information`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successProfileBtn');
        return e;
      }
    },

    async updateUserPhonesWithoutPermission({commit}, {id, data}) {
      try {
        return await API.put(`users/user-profile/public/${id}/phone-information`, data).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successProfileBtn');
        return e;
      }
    },


    async fetchUserProfileFiles({commit}, id) {
      try {
        return await API.get(`users/user-profile/${id}/file-entries-list`).then((response) =>{
          commit('setUserProfileFiles', {userProfileFiles: response.data.data});

          commit('setUserProfileFilesCarPassport', {userProfileFilesCarPassport: prepareProfileFilesArray(response.data.data.driver_document_files)});
          commit('setUserProfileFilesPassport', {userProfileFilesPassport: prepareProfileFilesArray(response.data.data.passport_document_files)});
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async fetchUserProfileFilesWithoutPermissions({commit}, id) {
      try {
        return await API.get(`users/user-profile/public/${id}/file-entries-list`).then((response) =>{
          commit('setUserProfileFiles', {userProfileFiles: response.data.data});

          commit('setUserProfileFilesCarPassport', {userProfileFilesCarPassport: prepareProfileFilesArray(response.data.data.driver_document_files)});
          commit('setUserProfileFilesPassport', {userProfileFilesPassport: prepareProfileFilesArray(response.data.data.passport_document_files)});
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async fetchUserDocumentTypes() {
      try {
        return await API.get(`users/user-profile/personal-document-list`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async fetchUserDocumentTypesWithoutPermissions() {
      try {
        return await API.get(`users/user-profile/public/personal-document-list`).then((response) =>{
          console.log(response);
          return response;
        });
      } catch (e) {
        return e;
      }
    },

    async uploadUserDocument({commit}, {id, data}) {
      commit('changeProfileBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`users/user-profile/${id}/personal-documents`, data).then((response) =>{
          console.log(response);
          // API.defaults.headers.post['Content-Type'] ='application/json';
          commit('successProfileBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successProfileBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },

    async adminUploadUserDocument({commit}, {id, data}) {
      commit('changeProfileBtn');
      API.defaults.headers.post['Accept'] = 'multipart/form-data';
      API.defaults.headers.post['Content-Type'] ='multipart/form-data';
      try {
        return await API.post(`users/user-profile/public/${id}/personal-documents`, data).then((response) =>{
          console.log(response);
          // API.defaults.headers.post['Content-Type'] ='application/json';
          commit('successProfileBtn');
          API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
          API.defaults.headers.post['Content-Type'] ='application/json'
          return response;
        });
      } catch (e) {
        commit('successProfileBtn');
        API.defaults.headers.post['Accept'] = 'application/json, text/plain, */*'
        API.defaults.headers.post['Content-Type'] ='application/json'
        return e;
      }
    },


    async removeUserDocument({commit}, id) {
      try {
        return await API.delete(`file-storage/${id}/delete`).then((response) =>{
          console.log(response);
          // API.defaults.headers.post['Content-Type'] ='application/json';
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async fetchUserItem({commit}, userId) {
      try {
        return await API.get(`users/${userId}`).then((response) =>{
          commit('setUserItem', {userItem: response.data.data});
          return response;
        });
      } catch (e) {
        commit('setLoadingUserItemEnd');
        return e;
      }
    },

    async fetchUsers({commit, getters}, filter = '') {
      if(!getters.getNextUsersPage)
        commit('setLoadingStart')
      try {
        return await API.get(`users${filter}`).then((response) =>{
          commit('setUsers', {users: response.data.data.data});
          commit('setUsersCommonList', {usersCommonList: response.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async fetchUsersFromFilter({commit}, filter) {
      try {
        return await API.get(`users${filter}`).then((response) =>{
          commit('setUsersFromFilter', {usersFromFilter: response.data.data.data});
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async fetchUsersFromFilterForSearch({commit}, filter) {
      try {
        return await API.get(`users/list-in-filter${filter}`).then((response) =>{
          commit('setUsersFromFilter', {usersFromFilter: response.data.data.data});
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async fetchAdminUsersFromFilter({commit}, filter) {
      try {
        return await API.get(`users/admin-users${filter}`).then((response) =>{
          commit('setUsersFromFilter', {usersFromFilter: response.data.data.data});
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async fetchImpersonateUsers({commit}) {
      commit('setLoadingStart');
      try {
        return await API.get('users/impersonate-users').then((response) =>{
          commit('setImpersonateUsers', {impersonateUsers: response.data.data});
          // commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async changeProfilePersonal({commit, getters}, {id, data}) {
      commit('changeProfileBtn');
      try {
        return await API.put(`users/user-profile/${id}/personal-information`, data).then((response) =>{

          if(_.has(response, 'data') && response.data.status === getters.GET_ERRORS.SUCCESS_CODE){
            let User = getters.getUserProfile
            User.user_personal_contact = response.data.data.user_personal_contact
            User.vat_number = response.data.data.vat_number
            commit('setUserProfile', {userProfile: User})
          }
          commit('successProfileBtn');
          return response;
        });
      } catch (e) {
        commit('successProfileBtn');
        return e;
      }
    },

    async changeProfilePersonalWithoutPermission({commit, getters}, {id, data}) {
      commit('changeProfileBtn');
      try {
        return await API.put(`users/user-profile/public/${id}/personal-information`, data).then((response) =>{

          if(_.has(response, 'data') && response.data.status === getters.GET_ERRORS.SUCCESS_CODE){
            let User = getters.getUserProfile
            User.user_personal_contact = response.data.data.user_personal_contact
            User.vat_number = response.data.data.vat_number
            commit('setUserProfile', {userProfile: User})
          }
          commit('successProfileBtn');
          return response;
        });
      } catch (e) {
        commit('successProfileBtn');
        return e;
      }
    },

    async changeProfileAddress({commit, getters}, {id, data}) {
      commit('changeProfileBtn');
      try {
        return await API.put(`users/user-profile/${id}/address-information`, data).then((response) =>{

          if(_.has(response, 'data') && response.data.status === getters.GET_ERRORS.SUCCESS_CODE){
            let User = getters.getUserProfile
            User.user_personal_contact = response.data.data.user_personal_contact
            User.user_alternative_contact = response.data.data.user_alternative_contact
            User.use_alternative_address = response.data.data.use_alternative_address
            User.itn_number = response.data.data.itn_number
            commit('setUserProfile', {userProfile: User})
          }
          commit('successProfileBtn');
          return response;
        });
      } catch (e) {
        commit('successProfileBtn');
        return e;
      }
    },

    async changeProfileAddressWithoutPermission({commit, getters}, {id, data}) {
      commit('changeProfileBtn');
      try {
        return await API.put(`users/user-profile/public/${id}/address-information`, data).then((response) =>{

          if(_.has(response, 'data') && response.data.status === getters.GET_ERRORS.SUCCESS_CODE){
            let User = getters.getUserProfile
            User.user_personal_contact = response.data.data.user_personal_contact
            User.user_alternative_contact = response.data.data.user_alternative_contact
            User.itn_number = response.data.data.itn_number
            commit('setUserProfile', {userProfile: User})
          }
          commit('successProfileBtn');
          return response;
        });
      } catch (e) {
        commit('successProfileBtn');
        return e;
      }
    },

    async changeProfileFulfilment({commit}, {id, data}) {
      try {
        return await API.put(`users/user-profile/${id}/fulfilment-information`, data).then((response) =>{

          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async changeProfileAlerts({commit}, {id, data}) {
      try {
        return await API.put(`users/user-profile/${id}/alerts-information`, data).then((response) =>{

          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async changeProfileTemplates({commit}, {id, data}) {
      try {
        return await API.put(`users/user-profile/${id}/profile-template-type`, data).then((response) =>{

          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },


    async changeProfilePassword({commit}, {id, data}) {
      commit('changeProfileBtn');
      try {
        return await API.post(`users/user-profile/${id}/change-password`, data).then((response) =>{
          commit('successProfileBtn');
          return response;
        });
      } catch (e) {
        commit('successProfileBtn');
        return e;
      }
    },

    async changeProfilePasswordWithoutPermission({commit}, {id, data}) {
      commit('changeProfileBtn');
      try {
        return await API.post(`users/user-profile/public/${id}/change-password`, data).then((response) =>{
          commit('successProfileBtn');
          return response;
        });
      } catch (e) {
        commit('successProfileBtn');
        return e;
      }
    },

    async changeProfileFunctionality({commit}, {id, data}) {
      commit('changeProfileBtn');
      try {
        return await API.post(`users/user-profile/${id}/change-functionality`, data).then((response) =>{
          commit('successProfileBtn');
          return response;
        });
      } catch (e) {
        commit('successProfileBtn');
        return e;
      }
    },

    async changeTwoFactorAuthType({commit, getters}, {id, data}) {
      try {
        return await API.put(`users/user-profile/${id}/auth-type`, data).then((response) =>{
          let profile = getters.getUserProfile
          profile.auth_type = data.auth_type
          commit('setUserProfile', {userProfile: profile})
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async updateUsers({commit}, {id, data}) {
      commit('setLoadingStart');
      try {
        return await API.put(`users/${id}`, data).then((response) =>{
          commit('setUsers', {users: response.data.data});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async createUsers({commit}, data) {
      commit('setLoadingStart');
      try {
        return await API.post('users', data).then((response) =>{
          // commit('setUsers', {users: response.data.users});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },

    async changeAvatar({commit}, {id, data}) {
      commit('setLoadingStart');
      try {
        return await API.post(`users/user-profile/${id}/upload-avatar`, data).then((response) =>{
          // console.log(response.data.data);
          // commit('setUsers', {users: response.data.users});
          // commit('setLoadingEnd');
          return response.data.data;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },


    async deleteUsers({commit}, userId) {
      commit('setLoadingStart');
      try {
        return await API.delete(`users/${userId}`).then((response) =>{
          // commit('setUsers', {users: response.data.users});
          commit('setLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd');
        return e;
      }
    },


    async changeServerLocale({commit}, id) {
      try {
        return await API.put(`users/user-setting/set-language`, {languageId: id}).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('setLoadingEnd')
        return e;
      }
    },




    async getUserManageItem({commit}, id) {
      commit('changeUserManageBtn')
      try {
        return await API.get(`users/manage/${id}`).then((response) =>{
          commit('successUserManageBtn')
          return response;
        });
      } catch (e) {
        commit('successUserManageBtn')
        return e;
      }
    },

    async getOrderItemFees({commit}) {
      commit('changeUserManageBtn')
      try {
        return await API.get(`users/manage/order-item-fees?limit=1000`).then((response) =>{
          commit('successUserManageBtn')
          return response;
        });
      } catch (e) {
        commit('successUserManageBtn')
        return e;
      }
    },

    async changeUserManage({commit}, data) {
      commit('changeUserManageBtn')
      try {
        return await API.post(`users/manage`, data).then((response) =>{
          commit('successUserManageBtn')
          return response;
        });
      } catch (e) {
        commit('successUserManageBtn')
        return e;
      }
    },

    async changeUserManageData({commit}, {id, data}) {
      commit('changeUserManageBtn')
      try {
        return await API.put(`users/manage/${id}`, data).then((response) =>{
          commit('successUserManageBtn')
          return response;
        });
      } catch (e) {
        commit('successUserManageBtn')
        return e;
      }
    },

    async getUserLastFiveIPs({commit}, id) {
      try {
        return await API.get(`users/ip-location/last?user_id=${id}`).then((response) => {
          return response
        })
      } catch (e) {
        commit('successUserManageBtn')
        return e;
      }
    },

    async getUserExport({commit}, {filter , exportType}) {
      commit('setExportBtnLoadingStart')
      try {
        return await API.get(`users/export/${exportType}${filter}`, { responseType: 'blob' }).then((response) =>{
          commit('setExportBtnLoadingEnd')
          return response
        })
      } catch (e) {
        commit('setLoadingEnd')
        commit('setExportBtnLoadingEnd')
        return e;
      }
    },

    async blockUser({commit}, {id, data}) {
      commit('changeManageUsersBtn');
      try {
        return await API.patch(`users/block/${id}`, data).then((response) =>{
          commit('successManageUsersBtn');
          return response;
        });
      } catch (e) {
        commit('successManageUsersBtn');
        return e;
      }
    },

    async massBlockUser({commit}, data) {
      commit('changeManageUsersBtn');
      try {
        return await API.patch(`users/mass-block`, data).then((response) =>{
          commit('successManageUsersBtn');
          return response;
        });
      } catch (e) {
        commit('successManageUsersBtn');
        return e;
      }
    },

    async userBalanceReportOnDate({commit}, data) {
      commit('changeManageUsersBtn');
      try {
        return await API.post(`/report/user-balance/balance-by-date`, data, { responseType: 'blob' }).then((response) =>{
          commit('successManageUsersBtn');
          return response;
        });
      } catch (e) {
        commit('successManageUsersBtn');
        return e;
      }
    },

    async saveUserIE559Form({commit}, data) {
      commit('changeManageUsersBtn');
      try {
        return await API.post(`/users/user-special-consolidation-form`, data).then((response) =>{
          commit('successManageUsersBtn');
          return response;
        });
      } catch (e) {
        commit('successManageUsersBtn');
        return e;
      }
    },
  }
}
