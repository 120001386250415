import {API} from "../../axios/axiosMainUrl";

const prefix = '/report/product/history'
const prefixTransit = '/report/product/transit-orders'
const prefixReserve = '/report/product/reserve-orders'
const prefixReportHistory = '/warehouse/storage/history'

export const productsReport = {
  state: {
    productsReport: [],
    productsReportItem: {},
    productsReportCommonList: [],
    productsReportBtn: false,
    productsReportLoading: true,
    nextProductsReportPage: false,
    productsReportForPage: 20,
    productsReportFilter: '',
  },

  mutations: {
    setProductsReportFilter: (state, payload) => {
      state.productsReportFilter = payload
    },

    setProductsReport: (state, payload) => {
      if(state.nextProductsReportPage) {
        state.productsReport = state.productsReport.concat(payload.productsReport)
      } else {
        state.productsReport = payload.productsReport
      }
    },

    setProductsReportCommonList: (state, payload) => {
      state.productsReportCommonList = payload.productsReportCommonList
    },

    setNextProductsReportPage: (state, payload) => {
      state.nextProductsReportPage = payload
    },

    setProductsReportItem: (state, payload) => {
      state.productsReportItem = payload.productsReportItem
    },

    setProductsReportLoadingStart: (state) => {
      state.productsReportLoading = true
    },

    setProductsReportLoadingEnd: (state) => {
      state.productsReportLoading = false
    },

    changeProductsReportBtn: (state) => {
      state.productsReportBtn = true
    },
    successProductsReportBtn: (state) => {
      state.productsReportBtn = false
    },
  },

  getters: {
    getProductsReportFilter: state => {
      return state.productsReportFilter
    },

    getProductsReportForPage: state => {
      return state.productsReportForPage
    },

    getProductsReport: state => {
      return state.productsReport
    },

    getProductsReportLoading: state => {
      return state.productsReportLoading
    },


    getNextProductsReportPage: state => {
      return state.nextProductsReportPage
    },

    getProductsReportItem: state => {
      return state.productsReportItem
    },

    getProductsReportCommonList: state => {
      return state.productsReportCommonList
    },

    getProductsReportBtn: state => {
      return state.productsReportBtn
    },
  },

  actions: {

    async fetchProductsReport({commit, getters}, filter = '') {
      if(!getters.getNextProductsReportPage)
        commit('setProductsReportLoadingStart')
      try {
        return await API.get(`${prefix}${filter}`).then((response) =>{
          commit('setProductsReport',{productsReport: response.data.data.data})
          // commit('setProductsReportCommonList',{productsReportCommonList: response.data.data})
          commit('setProductsReportCommonList',{productsReportCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setProductsReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsReportLoadingEnd');
        return e;
      }
    },

    async fetchProductsReportTransit({commit, getters}, url) {
      if(!getters.getNextProductsReportPage)
        commit('setProductsReportLoadingStart')
      try {
        return await API.get(`${prefixTransit}${url}`).then((response) =>{
          commit('setProductsReport',{productsReport: response.data.data.data})
          commit('setProductsReportCommonList',{productsReportCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.next_page_url,
                first_page_url: response.data.data.first_page_url,
                last_page_url: response.data.data.last_page_url,
                prev_page_url: response.data.data.prev_page_url
              }}})
          commit('setProductsReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsReportLoadingEnd');
        return e;
      }
    },

    async fetchProductsReportReserve({commit, getters}, url) {
      if(!getters.getNextProductsReportPage)
        commit('setProductsReportLoadingStart')
      try {
        return await API.get(`${prefixReserve}${url}`).then((response) =>{
          commit('setProductsReport',{productsReport: response.data.data.data})
          commit('setProductsReportCommonList',{productsReportCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.next_page_url,
                first_page_url: response.data.data.first_page_url,
                last_page_url: response.data.data.last_page_url,
                prev_page_url: response.data.data.prev_page_url
              }}})
          commit('setProductsReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsReportLoadingEnd');
        return e;
      }
    },

    async fetchProductsReportHistory({commit, getters}, url) {
      if(!getters.getNextProductsReportPage)
        commit('setProductsReportLoadingStart')
      try {
        return await API.get(`${prefixReportHistory}${url}`).then((response) =>{
          commit('setProductsReport',{productsReport: response.data.data.data})
          commit('setProductsReportCommonList',{productsReportCommonList: {...response.data.data.meta, ...{
                next_page_url: response.data.data.links.next,
                first_page_url: response.data.data.links.first,
                last_page_url: response.data.data.links.last,
                prev_page_url: response.data.data.links.prev
              }}})
          commit('setProductsReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsReportLoadingEnd');
        return e;
      }
    },

    async getProductsReport({commit}, id) {
      commit('setProductsReportLoadingStart');
      try {
        return await API.get(`${prefix}/${id}`).then((response) =>{
          commit('setProductsReportItem', {productsReportItem: response.data.data})
          commit('setProductsReportLoadingEnd');
          return response;
        });
      } catch (e) {
        commit('setProductsReportLoadingEnd');
        return e;
      }
    },

    async createProductsReport({commit}, data) {
      commit('changeProductsReportBtn');
      try {
        return await API.post(`${prefix}`, data).then((response) =>{
          commit('successProductsReportBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsReportBtn');
        return e;
      }
    },

    async updateProductsReport({commit}, {id, data}) {
      commit('changeProductsReportBtn');
      try {
        return await API.put(`${prefix}/${id}`, data).then((response) =>{
          commit('successProductsReportBtn');
          return response;
        });
      } catch (e) {
        commit('successProductsReportBtn');
        return e;
      }
    },

    async deleteProductsReport({commit}, id) {
      try {
        return await API.delete(`${prefix}/${id}`).then((response) =>{
          return response;
        });
      } catch (e) {
        commit('successProductsReportBtn');
        return e;
      }
    },

  }
}

//
// let fmbFba = [
//   {
//     type: 'fbm',
//     date: '25'
//   },
//   {
//     type: 'fba',
//     date: '24'
//   },
//   {
//     type: 'fbm',
//     date: '24'
//   },
//   {
//     type: 'fba',
//     date: '1'
//   },
//   {
//     type: 'fbm',
//     date: '23'
//   },
//
//   // next
//
//   {
//     type: 'fbm',
//     date: '20'
//   },
//   {
//     type: 'fbm',
//     date: '18'
//   },
//   {
//     type: 'fbm',
//     date: '23'
//   },
//
//
//   {
//     type: 'fba',
//     date: '2'
//   },
//
// ]
// fbm[last] -> get date -> query fba [date <= [fbmDate]]
//
// get fbm 25 fields -> 'last date fbm' -> fba <= 'last date fbm'