import {PERMISSIONS} from "../../../../staticData/permissionsStatic";
import {RETURN_GOODS_TYPES} from "@/staticData/staticVariables";


export function GetAdminMenuList() {

}

GetAdminMenuList.prototype = {

  ORDER_DISPATCHES: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.expressOrders,
          translation: 'menu_express',
          permissionLinkName: PERMISSIONS.ORDER_EXPRESS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.ordersConsolidation,
          translation: 'menu_consolidation',
          permissionLinkName: PERMISSIONS.CONSOLIDATION_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.ordersFBM,
          translation: 'menu_createOrders',
          permissionLinkName: PERMISSIONS.ORDER_FBM_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.ordersFBA,
          translation: 'menu_ordersFBA',
          permissionLinkName: PERMISSIONS.ORDER_FBA_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.ordersShipmentWarehouse,
          translation: 'menu_shipmentToWarehouse',
          permissionLinkName: PERMISSIONS.ORDER_INBOUND_GENERAL,
        },
        {
          linkPath: that.$store.getters.GET_PATHS.ordersMeestReturns,
          translation: 'menu_problemsMeestReturns',
          permissionLinkName: PERMISSIONS.ORDER_MEEST_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.ukrposhta,
          translation: 'menu_ukrposhta',
          permissionLinkName: PERMISSIONS.ORDER_INTERNAL_UKRPOSHTA,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.belposhta,
        //   translation: 'menu_belposhta',
        //   permissionLinkName: PERMISSIONS.ORDER_INTERNAL_BELPOSHTA,
        // },

        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.cdek,
          translation: 'menu_cdek',
          permissionLinkName: PERMISSIONS.ORDER_INTERNAL_CDEK,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.novaPoshta,
          translation: 'menu_novaPoshta',
          permissionLinkName: PERMISSIONS.ORDER_INTERNAL_NOVAPOSHTA,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.importOrders,
          translation: 'menu_importOrders',
          permissionLinkName: PERMISSIONS.ORDER_EXTERNAL_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.products,
        //   translation: 'menu_returns',
        // },

      ]
    }
  },

  FINANCE: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.cusPayments,
          translation: 'menu_customersPayments',
          permissionLinkName: PERMISSIONS.CUSTOMER_PAYMENT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.IE559Report,
          translation: 'menu_IE559Report',
          // permissionLinkName: PERMISSIONS.CUSTOMER_PAYMENT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeUnidentifiedPayments,
          translation: 'menu_unidentifiedPayments',
          permissionLinkName: PERMISSIONS.UNDEFINED_PAYMENT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeUkrTreasuresLink,
          translation: 'menu_paymentListings',
          permissionLinkName: PERMISSIONS.PAYMENT_LISTING_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeRequestFunds,
          translation: 'menu_requestFunds',
          count: that.$store.getters.GET_COMMON_AUTHORIZED?.balance_output_count,
          permissionLinkName: PERMISSIONS.BALANCE_OUTPUT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeRequestReturn,
          translation: 'menu_requestReturn',
          permissionLinkName: PERMISSIONS.REFUNDS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeInvoices,
          translation: 'menu_invoices',
          permissionLinkName: PERMISSIONS.INVOICES_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeMyPayments,
          translation: 'menu_myPayments',
          permissionLinkName: PERMISSIONS.MY_PAYMENT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financePayPalCases,
          translation: 'menu_payPalCases',
          permissionLinkName: PERMISSIONS.PAYPAL_CASE_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeNewPayment,
          translation: 'menu_financeNewPayments',
          permissionLinkName: PERMISSIONS.OTHER_PAYMENT_MANAGE_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeNewPaymentsPayoneer,
          translation: 'menu_financeNewPaymentsPayoneer',
          permissionLinkName: PERMISSIONS.CUSTOMER_PAYMENT_PAYONEER,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.forbiddenGoods,
          translation: 'menu_forbiddenGoods',
          count: that.$store.getters.GET_COMMON_AUTHORIZED?.forbidden_payment_transaction_count,
          permissionLinkName: PERMISSIONS.FORBIDDEN_PAYMENT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.negativeBalance,
          translation: 'menu_negativeBalance',
          permissionLinkName: PERMISSIONS.NEGATIVE_BALANCE_GENERAL,
        },
      ]
    }
  },

  WAREHOUSE: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.products,
          translation: 'menu_products',
          permissionLinkName: PERMISSIONS.PRODUCT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.refillProducts,
          translation: 'menu_refillProducts',
          permissionLinkName: PERMISSIONS.REFILL_PRODUCTS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.warehouseDisposalOrders,
          translation: 'menu_disposalorDer',
          permissionLinkName: PERMISSIONS.ORDER_DISPOSAL_GENERAL,
        },
      ]
    }
  },

  PROBLEMS: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.problemsParcels,
          translation: 'menu_parcelsDays',
          permissionLinkName: PERMISSIONS.PARCELS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.problemsFillClaim,
          translation: 'menu_fileAClaim',
          permissionLinkName: PERMISSIONS.CLAIM_GENERAL,
        },

        {
          parent: false,
          linkPath: '',
          translation: 'menu_returnPackages',
          hasChild: true,
          permissionLinkName: PERMISSIONS.RETURN_PARCEL_GENERAL,
          child: [
            {
              linkPath: that.$store.getters.GET_PATHS.problemsProcessedReturnsGoods + '/' + RETURN_GOODS_TYPES.PROCESSED_RETURNS,
              translation: 'menu_processedPackageReturns'
            },
            {
              linkPath: that.$store.getters.GET_PATHS.problemsProcessedReturnsGoods + '/' + RETURN_GOODS_TYPES.UNPROCESSED_RETURNS,
              translation: 'menu_unprocessedPackageReturns'
            },
            {
              linkOtherPath: that.$store.getters.GET_PATHS.problemsReturnGoodsUrl,
              linkPath: that.$store.getters.GET_PATHS.problemsUnidentifiedReturns,
              translation: 'menu_unidentifiedReturns'
            },
            {
              linkPath: that.$store.getters.GET_PATHS.problemsRequestReturnsGoods,
              translation: 'menu_requestPackageReturns'
            },
            {
              linkPath: that.$store.getters.GET_PATHS.problemsProcessedReturnsGoods + '/' + RETURN_GOODS_TYPES.TRASH,
              translation: 'menu_trashPackageReturns'
            },
            // {
            //   linkPath: that.$store.getters.GET_PATHS.ordersMeestReturns,
            //   translation: 'menu_problemsMeestReturns',
            //   permissionLinkName: PERMISSIONS.RETURN_PARCEL_GENERAL,
            // },
          ]
        },
      ]
    }
  },

  INCOME_EXPRESS: (that) => {
    return {
      links: [
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.incomeExpensesProfitReport,
        //   translation: 'menu_profitStatement',
        // },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.cusPayments,
        //   translation: 'menu_submissionsReport'
        // },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.expenses,
          translation: 'menu_expenses',
          permissionLinkName: PERMISSIONS.EXPENSE_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.otherIncome,
          translation: 'menu_otherIncome',
          permissionLinkName: PERMISSIONS.INCOME_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.cusPayments,
        //   translation: 'menu_payee'
        // },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.cusPayments,
        //   translation: 'menu_costType'
        // },
      ]
    }
  },

  REPORTS: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.inactiveFBMUsers,
          translation: 'menu_inactiveFBMUsers',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsConsolidationMeest,
          translation: 'menu_consolidationMeest',
          permissionLinkName: PERMISSIONS.CONSOLIDATION_UNION_MEEST_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.reportsConsolidationMeestSent,
        //   translation: 'menu_consolidationMeestSent',
        // },

        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsAPCDelcon,
          translation: 'menu_reportsAPCDelcon',
          permissionLinkName: PERMISSIONS.CONSOLIDATION_UNION_ORDER_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsConsolidation,
          translation: 'menu_reportsConsolidation',
          permissionLinkName: PERMISSIONS.CONSOLIDATION_UNION_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.warehouseProductsReport,
          translation: 'menu_productsReport',
          permissionLinkName: PERMISSIONS.ADMIN_REPORT_PRODUCTS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsConsolidationOrders,
          translation: 'menu_reportsConsolidationOrders',
          permissionLinkName: PERMISSIONS.REPORT_ORDERS_IN_CONSOLIDATION_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.shippingReport,
          translation: 'menu_shippingReportDHL',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.expressPoshtaReport,
          translation: 'menu_expressPoshtaReport',
          permissionLinkName: PERMISSIONS.ORDER_EXTERNAL_EXPRESS_POSHTA,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsProfitReportTotal,
          translation: 'menu_profitReport',
          permissionLinkName: PERMISSIONS.REPORT_PROFIT,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsGenerateReportPage,
          translation: 'menu_financeGenerateReportPage',
          permissionLinkName: PERMISSIONS.REPORT_ORDER_FBM_PRODUCTS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsGenerateReportFBAPage,
          translation: 'menu_financeGenerateReportFBAPage',
          permissionLinkName: PERMISSIONS.REPORT_ORDER_FBA_PRODUCTS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsProcessFBMReport,
          translation: 'menu_financeProcessFBMReport',
          permissionLinkName: PERMISSIONS.REPORT_ORDER_FBM_ADMIN_PROCESSED_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.trackingForPayPal,
          translation: 'menu_trackingForPayPal',
          permissionLinkName: PERMISSIONS.TRACKING_FOR_PAYPAL_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsConnectionsHistory,
          translation: 'menu_connectionsHistory',
          permissionLinkName: PERMISSIONS.REPORT_CONNECTION_HISTORY_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.financeReportCells,
          translation: 'menu_financeReportCells',
          permissionLinkName: PERMISSIONS.REPORT_CELLS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsFastOrders,
          translation: 'menu_fastOrders',
          permissionLinkName: PERMISSIONS.FAST_ORDER_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsExpressFastOrders,
          translation: 'menu_expressFastOrders',
          permissionLinkName: PERMISSIONS.FAST_EXPRESS_ORDER,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.reportsExpressProblem,
          translation: 'menu_expressProblem',
          permissionLinkName: PERMISSIONS.FAST_EXPRESS_ORDER,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.transactionExport,
          translation: 'menu_TransactionExport',
          permissionLinkName: PERMISSIONS.TRANSACTION_EXPORT,
        },
      ]
    }
  },

  HEARTYSAN: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.heartysanCategory,
          translation: 'menu_HeartysanCategory',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.heartysanUsers,
          translation: 'menu_heartysanUsers',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.heartysanManufacturerListings,
          translation: 'menu_heartysanManufacturerListings',
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.heartysanChoice,
        //   translation: 'menu_heartysanChoice',
        //   hiddenOptionArr: 'heartysanChoice',
        // },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.heartysanManufacturerListings,
        //   translation: 'menu_heartysanManufacturerListings',
        //   hiddenOptionArr: 'heartysanManufacturer',
        // },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.heartysanSellerListings,
        //   translation: 'menu_heartysanSellerListings',
        //   hiddenOptionArr: 'heartysanSeller',
        // },
      ]
    }
  },

  MARKETPLACE: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.marketplaceProducts,
          translation: 'menu_marketplaceProducts',
          permissionLinkName: PERMISSIONS.UKRAINIAN_TREASURES_ACCESS,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.marketplaceReviews,
          translation: 'menu_marketplaceReviews',
          permissionLinkName: PERMISSIONS.UKRAINIAN_TREASURES_REVIEWS,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.marketplaceRates,
          translation: 'menu_marketplaceRates',
          permissionLinkName: PERMISSIONS.UKRAINIAN_TREASURES_SHIPPING_RATE,
        },
      ]
    }
  },

  SETTINGS: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsEdit,
          translation: 'menu_mainSettingsEdit',
          permissionLinkName: PERMISSIONS.SETTING_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.accounts,
          translation: 'menu_personalAccount',
          permissionLinkName: PERMISSIONS.ACCOUNT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.accountTypes,
          translation: 'menu_accountTypes',
          permissionLinkName: PERMISSIONS.ACCOUNT_BANKS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsShops,
          translation: 'menu_shops',
          permissionLinkName: PERMISSIONS.SHOPS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.forbiddenKeywords,
          translation: 'menu_forbiddenKeywords',
          permissionLinkName: PERMISSIONS.FORBIDDEN_WORDS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsEtsyListings,
          translation: 'menu_productImportFromEtsy',
          permissionLinkName: PERMISSIONS.LISTING_DOWNLOAD_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsEtsyTransfer,
          translation: 'menu_transferEtsy',
          permissionLinkName: PERMISSIONS.LISTING_DOWNLOAD_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.products,
        //   translation: 'menu_importFromEtsyToWPOrOs',
        // },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsManageUsersLink,
          translation: 'menu_subuser',
          permissionLinkName: PERMISSIONS.USER_MANAGE_ACCESS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsProfilesLink,
          translation: 'menu_profiles',
          permissionLinkName: PERMISSIONS.PROFILE_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.downloadPlugins,
          translation: 'menu_modules',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsCustomsInformation,
          translation: 'menu_customsInformation',
          // permissionLinkName: PERMISSIONS.DELIVERY_COUNTRY_CUSTOMS_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.mainSettingsNotifications,
        //   translation: 'menu_notifications',
        //   permissionLinkName: PERMISSIONS.NOTIFICATION_GENERAL,
        // },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsProformCategory,
          translation: 'menu_proform',
          hasChild: true,
          permissionLinkName: PERMISSIONS.PROFORM_GENERAL,
          child: [
            {
              linkPath: that.$store.getters.GET_PATHS.mainSettingsProformCategory,
              translation: 'menu_proformCategory'
            },
            {
              linkPath: that.$store.getters.GET_PATHS.mainSettingsProformData,
              translation: 'menu_proformData'
            },
            {
              linkPath: that.$store.getters.GET_PATHS.mainSettingsHSCodes,
              translation: 'menu_hSCodes'
            },
          ]
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.mainSettingsMyProfile,
        //   translation: 'menu_myProfile'
        // },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsWarehouseManagement,
          translation: 'menu_warehouseManagementConsolidation',
          permissionLinkName: PERMISSIONS.WAREHOUSE_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.mainSettingsOtherShippers,
        //   translation: 'menu_mainSettingsOtherShippers'
        // },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsOrderPrice,
          translation: 'menu_mainSettingsOrderPrice',
          permissionLinkName: PERMISSIONS.ORDER_PRICE_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsOrderSize,
          translation: 'menu_mainSettingsOrderSize',
          permissionLinkName: PERMISSIONS.ORDER_SIZES_ACCESS,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.shippingCompany,
          translation: 'menu_shippingCompany',
          permissionLinkName: PERMISSIONS.DELIVERY_SERVICE_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsShippingCost,
          translation: 'menu_mainSettingsShippingCost',
          permissionLinkName: PERMISSIONS.DELIVERY_SERVICE_COST_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsPackagingTypes,
          translation: 'menu_mainSettingsPackagingTypes',
          permissionLinkName: PERMISSIONS.PACKING_PRICE_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsCells,
          translation: 'menu_mainSettingsCells',
          permissionLinkName: PERMISSIONS.WAREHOUSE_STORAGE_CELL_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.settingsOrderBin,
          translation: 'menu_mainSettingsOrderBins',
          permissionLinkName: PERMISSIONS.WAREHOUSE_STORAGE_ORDER_BINS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsInventory,
          translation: 'menu_mainSettingsInventory',
          permissionLinkName: PERMISSIONS.INVENTORY_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsIOSSNumbers,
          translation: 'menu_IOSSNumbers',
          permissionLinkName: PERMISSIONS.IOSS_NUMBERS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsInsuranceSkladUsa,
          translation: 'menu_InsuranceSkladUsa',
          permissionLinkName: PERMISSIONS.INSURANCE_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.thermalPrint,
          translation: 'menu_thermalPrint',
          permissionLinkName: PERMISSIONS.CUSTOM_PRODUCT_THERMAL_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.trackTest,
          translation: 'menu_trackTest',
          permissionLinkName: PERMISSIONS.REPORT_SEND_TRACKING_NUMBER_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.consolidationUsers,
          translation: 'menu_consolidationUsers',
          permissionLinkName: PERMISSIONS.CONSOLIDATION_PERSONS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.authTokens,
          translation: 'menu_authTokens',
          permissionLinkName: PERMISSIONS.AUTH_TOKENS_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsFreshChat,
          translation: 'menu_freshChat',
          permissionLinkName: PERMISSIONS.FRESH_CHAT_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsZonePrice,
          translation: 'menu_zonePrice',
          permissionLinkName: PERMISSIONS.EXPRESS_PRICE_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsEasypostInvoices,
          translation: 'menu_easypostInvoices',
          permissionLinkName: PERMISSIONS.EASY_POST_INVOICES_LIST,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsPromoCode,
          translation: 'menu_PromoCode',
          permissionLinkName: PERMISSIONS.PROMOCODE_SETTINGS,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.settingsAdminAuth,
          translation: 'menu_systemAdminAuth',
          permissionLinkName: PERMISSIONS.TRANSLATION_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.scanBarcodeFBM,
          translation: 'menu_scanBarcodeFBM',
          permissionLinkName: PERMISSIONS.SCAN_FBM_LABEL_GENERAL,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.mainSettingsCalculatorSettings,
          translation: 'menu_calculatorSettings',
          permissionLinkName: PERMISSIONS.CALCULATOR_SETTING_PERMISSION,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.settingsTranslations,
        //   translation: 'menu_translations',
        //   permissionLinkName: PERMISSIONS.TRANSLATION_GENERAL,
        // },

      ]
    }
  },

  SYSTEM: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.settingsAdminAuth,
          translation: 'menu_systemAdminAuth',
          permissionLinkName: PERMISSIONS.WITHOUT_TWO_FACTOR_AUTH,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.settingsUsers,
          translation: 'menu_systemUsers',
          permissionLinkName: PERMISSIONS.WITHOUT_TWO_FACTOR_AUTH,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.settingsRoles,
          translation: 'menu_roles',
          permissionLinkName: PERMISSIONS.WITHOUT_TWO_FACTOR_AUTH,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.settingsPermissions,
          translation: 'menu_permissions',
          permissionLinkName: PERMISSIONS.WITHOUT_TWO_FACTOR_AUTH,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.settingsPermissionsGroup,
          translation: 'menu_permissionsGroup',
          permissionLinkName: PERMISSIONS.WITHOUT_TWO_FACTOR_AUTH,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.settingsVueComponents,
          translation: 'menu_vueComponents',
          permissionLinkName: PERMISSIONS.WITHOUT_TWO_FACTOR_AUTH,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.settingsTranslations,
          translation: 'menu_translations',
          permissionLinkName: PERMISSIONS.WITHOUT_TWO_FACTOR_AUTH,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.settingsTranslationsGroup,
          translation: 'menu_translationsGroups',
          permissionLinkName: PERMISSIONS.WITHOUT_TWO_FACTOR_AUTH,
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.errorLogs,
          translation: 'menu_ErrorLogs',
        },
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.errorLogsBackend,
          translation: 'menu_ErrorLogsBackend',
        },
      ]
    }
  },

  STATISTICS: (that) => {
    return {
      links: [
        {
          parent: false,
          linkPath: that.$store.getters.GET_PATHS.statisticsDeliveryStatistics,
          translation: 'menu_deliveryStatistics',
          permissionLinkName: PERMISSIONS.DELIVERY_STATISTIC_GENERAL,
        },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.statisticsEtsyRank,
        //   translation: 'menu_etsyRank'
        // },
        // {
        //   parent: false,
        //   linkPath: that.$store.getters.GET_PATHS.cusPayments,
        //   translation: 'menu_googleAnalytics'
        // },
      ]
    }
  },

}
