import Vue from 'vue'
import VueRouter from 'vue-router'
// import HelloWorld from '../components/HelloWorld.vue'

import {routerPaths} from './routerPaths'
import {store} from '../store/store'

Vue.use(VueRouter)



// *************  Module Include ************ //

const Auth = () => import(/* webpackChunkName: "group-auth" */ '../components/modules/AuthModule/Auth.vue')
const Registration = () => import(/* webpackChunkName: "group-auth" */ '../components/modules/RegistrationModule/Registration.vue')
const Dashboard = () => import(/* webpackChunkName: "group-auth" */ '../components/modules/DashboardModule/Dashboard.vue')
const SettingsModule = () => import(/* webpackChunkName: "group-settings" */ '../components/modules/SettingsModule/Settings.vue')
const CusPaymentsModule = () => import(/* webpackChunkName: "group-auth" */ '../components/modules/CusPaymentsModule/CusPaymentsModule.vue')
const ExpressOrdersModule = () => import(/* webpackChunkName: "group-auth" */ '../components/modules/ExpressOrdersModule/ExpressOrdersModule.vue')
const OrdersFBMModule = () => import(/* webpackChunkName: "group-fbm" */ '../components/modules/OrdersFBMModule/OrdersFBMModule.vue')
const OrdersFBAModule = () => import(/* webpackChunkName: "group-fba" */ '../components/modules/OrdersFBAModule/OrdersFBAModule.vue')
const UkrposhtaModule = () => import(/* webpackChunkName: "group-ukrposhta" */ '../components/modules/UkrposhtaModule/UkrposhtaModule.vue')
const BelposhtaModule = () => import(/* webpackChunkName: "group-belposhta" */ '../components/modules/BelposhtaModule/BelposhtaModule.vue')
const CDEKModule = () => import(/* webpackChunkName: "group-CDEK" */ '../components/modules/CDEKModule/CDEKModule.vue')
const NovaPoshtaModule = () => import(/* webpackChunkName: "group-nova-poshta" */ '../components/modules/NovaPoshtaModule/NovaPoshtaModule.vue')
const AuthenticationModule = () => import(/* webpackChunkName: "group-authentication" */ '../components/modules/AuthenticationModule/Authentication.vue')
const ProfileModule = () => import(/* webpackChunkName: "group-profile" */ '../components/modules/ProfileModule/ProfileModule.vue')
const CustomsInfoModule = () => import(/* webpackChunkName: "group-customs-info" */ '../components/modules/CustomsInfoModule/CustomsInfoModule.vue')
const NotificationModule = () => import(/* webpackChunkName: "group-notifications" */ '../components/modules/NotificationModule/NotificationModule.vue')
const BalanceHistoryModule = () => import(/* webpackChunkName: "group-balance-history" */ '../components/modules/BalanceHistoryModule/BalanceHistoryModule.vue')
const ShopsModule = () => import(/* webpackChunkName: "group-shops" */ '../components/modules/ShopsModule/ShopsModule.vue')
const WarehouseManagementModule = () => import(/* webpackChunkName: "group-warehouse-management" */ '../components/modules/WarehouseManagementModule/WarehouseManagement.vue')
const ProductsModule = () => import(/* webpackChunkName: "group-products" */ '../components/modules/ProductsModule/ProductsModule.vue')
const RefillProductsModule = () => import(/* webpackChunkName: "group-refill-products" */ '../components/modules/RefillProductsModule/RefillProductsModule.vue')
const ProductsReportModule = () => import(/* webpackChunkName: "group-product-report" */ '../components/modules/ProductsReportModule/ProductsReportModule.vue')
const DisposalOrdersModule = () => import(/* webpackChunkName: "group-products" */ '../components/modules/DisposalOrdersModule/DisposalOrdersModule.vue')
const ImportOrdersModule = () => import(/* webpackChunkName: "group-import-orders" */ '../components/modules/ImportOrdersModule/ImportOrdersModule.vue')
const MyPaymentsModule = () => import(/* webpackChunkName: "group-my-payments" */ '../components/modules/MyPaymentsModule/MyPaymentsModule.vue')
const UnidentifiedPaymentsModule = () => import(/* webpackChunkName: "group-unidentified-payments" */ '../components/modules/UnidentifiedPaymentsModule/UnidentifiedPaymentsModule.vue')
const UkrTreasuresModule = () => import(/* webpackChunkName: "group-ukr-treasures" */ '../components/modules/UkrTreasuresModule/UkrTreasuresModule.vue')
const PayPalCasesModule = () => import(/* webpackChunkName: "group-paypal-cases" */ '../components/modules/PayPalCasesModule/PayPalCasesModule.vue')
const ManageUsersModule = () => import(/* webpackChunkName: "group-manage-users" */ '../components/modules/ManageUsersModule/ManageUsersModule.vue')
const RequestReturnModule = () => import(/* webpackChunkName: "group-request-return" */ '../components/modules/RequestReturnModule/RequestReturnModule.vue')
const RequestFundsModule = () => import(/* webpackChunkName: "group-request-funds" */ '../components/modules/RequestFundsModule/RequestFundsModule.vue')
const AccountsModule = () => import(/* webpackChunkName: "group-accounts" */ '../components/modules/AccountsModule/AccountsModule.vue')
const UsersModule = () => import(/* webpackChunkName: "group-users" */ '../components/modules/UsersModule/UsersModule.vue')
const EtsyListingsModule = () => import(/* webpackChunkName: "group-etsy-listings" */ '../components/modules/EtsyListingsModule/EtsyListingsModule.vue')
const EtsyTransferModule = () => import(/* webpackChunkName: "group-etsy-transfer" */ '../components/modules/EtsyTransferModule/EtsyTransferModule.vue')
const CalculatorModule = () => import(/* webpackChunkName: "group-calculator" */ '../components/modules/CalculatorModule/CalculatorModule.vue')
const CalculatorEngravingModule = () => import(/* webpackChunkName: "group-calculator-engraving" */ '../components/modules/CalculatorEngravingModule/CalculatorEngravingModule.vue')
const EtsyRankModule = () => import(/* webpackChunkName: "group-etsy-rank-module" */ '../components/modules/EtsyRankModule/EtsyRankModule.vue')
const OrdersInboundModule = () => import(/* webpackChunkName: "group-shipment-warehouse" */ '../components/modules/OrdersInboundModule/OrdersInboundModule.vue')
const ConsolidationModule = () => import(/* webpackChunkName: "group-consolidation" */ '../components/modules/ConsolidationModule/ConsolidationModule.vue')
const FillClaimModule = () => import(/* webpackChunkName: "group-fill-claim" */ '../components/modules/FillClaimModule/FillClaimModule.vue')
const ParcelsModule = () => import(/* webpackChunkName: "group-parcels" */ '../components/modules/ParcelsModule/ParcelsModule.vue')
const DeliveryStatisticsModule = () => import(/* webpackChunkName: "group-delivery-statistic" */ '../components/modules/DeliveryStatisticsModule/DeliveryStatisticsModule.vue')
// const UnidentifiedReturnsModule = () => import(/* webpackChunkName: "group-unidentified-returns" */ '../components/modules/UnidentifiedReturnsModule/UnidentifiedReturnsModule.vue')
const ReturnGoodsModule = () => import(/* webpackChunkName: "group-my-returns" */ '../components/modules/ReturnGoodsModule/ReturnGoodsModule.vue')
const ProfitReportModule = () => import(/* webpackChunkName: "group-profit-report" */ '../components/modules/ProfitReportModule/ProfitReportModule.vue')
const ProfilesModule = () => import(/* webpackChunkName: "group-profiles" */ '../components/modules/ProfilesModule/ProfilesModule.vue')
const InvoicesModule = () => import(/* webpackChunkName: "group-invoices" */ '../components/modules/InvoicesModule/InvoicesModule.vue')
const ProformCategoryModule = () => import(/* webpackChunkName: "group-proform-category" */ '../components/modules/ProformCategoryModule/ProformCategoryModule.vue')
const ProformDataModule = () => import(/* webpackChunkName: "group-proform-data" */ '../components/modules/ProformDataModule/ProformDataModule.vue')
const NewPaymentsModule = () => import(/* webpackChunkName: "group-new-payments" */ '../components/modules/NewPaymentsModule/NewPaymentsModule.vue')
const OtherShippersModule = () => import(/* webpackChunkName: "group-other-shippers" */ '../components/modules/OtherShippersModule/OtherShippersModule.vue')
const ReportCellsModule = () => import(/* webpackChunkName: "group-report-cells" */ '../components/modules/ReportCellsModule/ReportCellsModule.vue')
const ProcessFBMReportModule = () => import(/* webpackChunkName: "group-process-fbm-report" */ '../components/modules/ProcessFBMReportModule/ProcessFBMReportModule.vue')
const ConsolidationOrdersModule = () => import(/* webpackChunkName: "group-consolidation-orders" */ '../components/modules/ConsolidationOrdersModule/ConsolidationOrdersModule')
const GenerateReportPageModule = () => import(/* webpackChunkName: "group-generate-report-page" */ '../components/modules/GenerateReportPageModule/GenerateReportPageModule.vue')
const GenerateReportFBAPageModule = () => import(/* webpackChunkName: "group-generate-report-fba-page" */ '../components/modules/GenerateReportFBAPageModule/GenerateReportFBAPageModule.vue')
const ConnectionsHistoryModule = () => import(/* webpackChunkName: "group-connections-history" */ '../components/modules/ConnectionsHistoryModule/ConnectionsHistoryModule')
const FastOrdersModule = () => import(/* webpackChunkName: "group-fast-orders" */ '../components/modules/FastOrdersModule/FastOrdersModule')
const APCDelconModule = () => import(/* webpackChunkName: "group-apc-delcon-page" */ '../components/modules/APCDelconModule/APCDelconModule.vue')
const ExpensesModule = () => import(/* webpackChunkName: "group-expenses" */ '../components/modules/ExpensesModule/ExpensesModule.vue')
const OtherIncomeModule = () => import(/* webpackChunkName: "group-other-income" */ '../components/modules/OtherIncomeModule/OtherIncomeModule.vue')
const MainSettingsModule = () => import(/* webpackChunkName: "group-main-settings" */ '../components/modules/MainSettingsModule/MainSettingsModule.vue')
const ConsolidationUnionModule = () => import(/* webpackChunkName: "group-consolidation-report" */ '../components/modules/ConsolidationUnionModule/ConsolidationUnionModule.vue')
const OrderPriceModule = () => import(/* webpackChunkName: "group-order-price" */ '../components/modules/OrderPriceModule/OrderPriceModule.vue')
const ZonePriceModule = () => import(/* webpackChunkName: "group-zone-price" */ '../components/modules/ZonePriceModule/ZonePriceModule.vue')
const ShippingCompanyModule = () => import(/* webpackChunkName: "group-other-shippers" */ '../components/modules/ShippingCompanyModule/ShippingCompanyModule.vue')
const TrackingPayPalModule = () => import(/* webpackChunkName: "group-tracking-paypal" */ '../components/modules/TrackingPayPalModule/TrackingPayPalModule.vue')
const AccountBanksModule = () => import(/* webpackChunkName: "group-account-types" */ '../components/modules/AccountBanksModule/AccountBanksModule.vue')
const ShippingCostModule = () => import(/* webpackChunkName: "group-shipping-cost" */ '../components/modules/ShippingCostModule/ShippingCostModule.vue')
const PackagingTypesModule = () => import(/* webpackChunkName: "group-packaging-types" */ '../components/modules/PackagingTypesModule/PackagingTypesModule.vue')
const CellsModule = () => import(/* webpackChunkName: "group-cells" */ '../components/modules/CellsModule/CellsModule.vue')
const OrderBinModule = () => import(/* webpackChunkName: "group-order-bin" */ '../components/modules/OrderBinModule/OrderBinModule.vue')
const IOSSNumbersModule = () => import(/* webpackChunkName: "group-ioss-numbers" */ '../components/modules/IOSSNumbersModule/IOSSNumbersModule.vue')
const InsuranceSkladUsaModule = () => import(/* webpackChunkName: "group-insurance-sklad-usa" */ '../components/modules/InsuranceSkladUsaModule/InsuranceSkladUsaModule.vue')
const InventoryModule = () => import(/* webpackChunkName: "group-inventory" */ '../components/modules/InventoryModule/InventoryModule.vue')
const ForbiddenGoodsModule = () => import(/* webpackChunkName: "group-forbidden-goods" */ '../components/modules/ForbiddenGoodsModule/ForbiddenGoodsModule.vue')
const ForbiddenKeywordsModule = () => import(/* webpackChunkName: "group-forbidden-goods" */ '../components/modules/ForbiddenKeywordsModule/ForbiddenKeywordsModule.vue')
const ThermalPrintModule = () => import(/* webpackChunkName: "group-thermal-print" */ '../components/modules/ThermalPrintModule/ThermalPrintModule.vue')
const HSCodesModule = () => import(/* webpackChunkName: "group-hs-codes" */ '../components/modules/HSCodesModule/HSCodesModule.vue')
const GetFileModule = () => import(/* webpackChunkName: "group-hs-codes" */ '../components/modules/GetFileModule/GetFileModule.vue')
const ShippingInsurancesModule = () => import(/* webpackChunkName: "shipping-insurances" */ '../components/modules/ShippingInsurancesModule/ShippingInsurancesModule.vue')
const GenerateFileModule = () => import(/* webpackChunkName: "generate-file" */ '../components/modules/GenerateFileModule/GenerateFileModule.vue')
const TrackTestModule = () => import(/* webpackChunkName: "track-test" */ '../components/modules/TrackTestModule/TrackTestModule.vue')
const ConsolidationUsersModule = () => import(/* webpackChunkName: "consolidation-user" */ '../components/modules/ConsolidationUsersModule/ConsolidationUsersModule.vue')
const AuthTokensModule = () => import(/* webpackChunkName: "auth-tokens" */ '../components/modules/AuthTokensModule/AuthTokensModule.vue')
const ScanBarcodeFBMModule = () => import(/* webpackChunkName: "group-scan-barcode-fbm" */ '../components/modules/ScanBarcodeFBMModule/ScanBarcodeFBMModule')
const MultiSearchModule = () => import(/* webpackChunkName: "multi-search" */ '../components/modules/MultiSearchModule/MultiSearchModule.vue')
const SearchModule = () => import(/* webpackChunkName: "search" */ '../components/modules/SearchModule/SearchModule.vue')
const NegativeBalanceModule = () => import(/* webpackChunkName: "negative-balance" */ '../components/modules/NegativeBalanceModule/NegativeBalanceModule.vue')
const ConsolidationMeestModule = () => import(/* webpackChunkName: "consolidation-meest" */ '../components/modules/ConsolidationMeestModule/ConsolidationMeestModule.vue')
const FreshChatModule = () => import(/* webpackChunkName: "fresh-chat" */ '../components/modules/FreshChatModule/FreshChatModule.vue')
const ChatModule = () => import(/* webpackChunkName: "sms-chat" */ '../components/modules/ChatModule/ChatModule.vue')
const HeartysanModule = () => import(/* webpackChunkName: "sms-chat" */ '../components/modules/HeartysanModule/HeartysanModule.vue')
const HeartysanCategoryModule = () => import(/* webpackChunkName: "heartysan-category" */ '../components/modules/HeartysanCategoryModule/HeartysanCategoryModule.vue')
const HeartysanUsersModule = () => import(/* webpackChunkName: "heartysan-users" */ '../components/modules/HeartysanUsersModule/HeartysanUsersModule.vue')
const HeartysanChatModule = () => import(/* webpackChunkName: "heartysan-chat" */ '../components/modules/HeartysanChatModule/HeartysanChatModule.vue')
const HeartysanEtsyModule = () => import(/* webpackChunkName: "heartysan-chat" */ '../components/modules/HeartysanEtsyModule/HeartysanEtsyModule.vue')
const ErrorLogsModule = () => import(/* webpackChunkName: "error-logs" */ '../components/modules/ErrorLogsModule/ErrorLogsModule.vue')
const ErrorLogsBackendModule = () => import(/* webpackChunkName: "error-logs-backend" */ '../components/modules/ErrorLogsBackendModule/ErrorLogsBackendModule.vue')
const ShippingReportModule = () => import(/* webpackChunkName: "shipping-report" */ '../components/modules/ShippingReportModule/ShippingReportModule.vue')
const InactiveFBMUsersModule = () => import(/* webpackChunkName: "inactive-FBM-users" */ '../components/modules/InactiveFBMUsersModule/InactiveFBMUsersModule.vue')
const TransactionExportModule = () => import(/* webpackChunkName: "transaction-export-module" */ '../components/modules/TransactionExportModule/TransactionExportModule.vue')
const MarketplaceProductsModule = () => import(/* webpackChunkName: "marketplace-products-module" */ '../components/modules/MarketplaceProductsModule/MarketplaceProductsModule.vue')
const MarketplaceReviewsModule = () => import(/* webpackChunkName: "marketplace-reviews-module" */ '../components/modules/MarketplaceReviewsModule/MarketplaceReviewsModule.vue')
const MarketplaceSettingsModule = () => import(/* webpackChunkName: "marketplace-settings-module" */ '../components/modules/MarketplaceSettingsModule/MarketplaceSettingsModule.vue')
const MarketplaceRatesModule = () => import(/* webpackChunkName: "marketplace-rates-module" */ '../components/modules/MarketplaceRatesModule/MarketplaceRatesModule.vue')
const BalancePayoneerHistoryModule = () => import(/* webpackChunkName: "balance-payoneer-history-module" */ '../components/modules/BalancePayoneerHistoryModule/BalancePayoneerHistoryModule.vue')
const IE559ReportModule = () => import(/* webpackChunkName: "IE559-report-module" */ '../components/modules/IE559ReportModule/IE559ReportModule.vue')

const ScanBarcodeModule = () => import(/* webpackChunkName: "group-scan-barcode" */ '../components/modules/ScanBarcodeModule/ScanBarcodeModule.vue')
const DownloadPluginsModule = () => import(/* webpackChunkName: "group-import-orders" */ '../components/modules/DownloadPluginsModule/DownloadPluginsModule.vue')
const ExportRequestsModule = () => import(/* webpackChunkName: "group-export-requests" */ '../components/modules/ExportRequestsModule/ExportRequestsModule.vue')

const OrderSizeModule = () => import(/* webpackChunkName: "group-order-size" */ '../components/modules/OrderSizeModule/OrderSizeModule.vue')
const PromoCodeModule = () => import(/* webpackChunkName: "group-promo-code" */ '../components/modules/PromoCodeModule/PromoCodeModule.vue')
const EasypostInvoicesModule = () => import(/* webpackChunkName: "group-easypost-invoices" */ '../components/modules/EasypostInvoicesModule/EasypostInvoicesModule.vue')
const SynchronizeShopifyModule = () => import(/* webpackChunkName: "group-synchronize-shopify" */ '../components/modules/SynchronizeShopifyModule/SynchronizeShopifyModule.vue')
const NewPaymentsPayoneerModule = () => import(/* webpackChunkName: "group-new-payments-payoneer" */ '../components/modules/NewPaymentsPayoneerModule/NewPaymentsPayoneerModule.vue')
const ExpressPoshtaReportModule = () => import(/* webpackChunkName: "group-express-poshta-report" */ '../components/modules/ExpressPoshtaReportModule/ExpressPoshtaReportModule.vue')
const CalculatorSettingsModule = () => import(/* webpackChunkName: "group-calculator-settings" */ '../components/modules/CalculatorSettingsModule/CalculatorSettingsModule.vue')

const OrdersMeestModule = () => import(/* webpackChunkName: "group-orders-meest" */ '../components/modules/OrdersMeestModule/OrdersMeestModule.vue')

const ExpressFastOrdersModule = () => import(/* webpackChunkName: "group-orders-fast-express" */ '../components/modules/ExpressFastOrdersModule/ExpressFastOrdersModule.vue')
const ExpressProblemModule = () => import(/* webpackChunkName: "group-orders-fast-express" */ '../components/modules/ExpressProblemModule/ExpressProblemModule.vue')

const GraphicsStatisticModule = () => import(/* webpackChunkName: "group-graphics-statistic" */ '../components/modules/GraphicsStatisticModule/GraphicsStatisticModule.vue')
const NotificationListModule = () => import(/* webpackChunkName: "group-notification-list" */ '../components/modules/NotificationListModule/NotificationListModule.vue')

const SynchronizeShop = () => import(/* webpackChunkName: "group-synchronize-shop" */ '../components/modules/NoPageModule/SynchronizeShop.vue')
const ShopifyConnection = () => import(/* webpackChunkName: "group-shopify-connection" */ '../components/modules/NoPageModule/ShopifyConnection/ShopifyConnection.vue')
const HistoryOfChangesComponent = () => import(/* webpackChunkName: "group-history-of-changes-component" */ '../components/modules/NoPageModule/HistoryOfChangesComponent/HistoryOfChangesComponent.vue')
const NotFoundModule = () => import(/* webpackChunkName: "group-not-found" */ '../components/modules/NotFoundModule/NotFoundModule.vue')
const Error503Module = () => import(/* webpackChunkName: "group-not-found" */ '../components/modules/NotFoundModule/Error503Module.vue')
const Error504Module = () => import(/* webpackChunkName: "group-not-found" */ '../components/modules/NotFoundModule/Error504Module.vue')

// *************  End Module Include ************ //

// *************  Module Routes Include ************ //

import {DashboardRoutes} from '../components/modules/DashboardModule/DashboardRoutes'
import {AuthRoutes} from '../components/modules/AuthModule/AuthRoutes'
import {SettingsRoutes} from '../components/modules/SettingsModule/SettingsRoutes.js'
import {ExpressRoutes} from '../components/modules/ExpressOrdersModule/ExpressRoutes'
import {OrdersFBMRoutes} from '../components/modules/OrdersFBMModule/OrdersFBMRoutes.js'
import {OrdersFBARoutes} from '../components/modules/OrdersFBAModule/OrdersFBARoutes.js'
import {UkrposhtaRoutes} from '../components/modules/UkrposhtaModule/UkrposhtaRoutes.js'
import {BelposhtaRoutes} from '../components/modules/BelposhtaModule/BelposhtaRoutes.js'
import {CDEKRoutes} from '../components/modules/CDEKModule/CDEKRoutes.js'
import {NovaPoshtaRoutes} from '../components/modules/NovaPoshtaModule/NovaPoshtaRoutes.js'
import {AuthenticationRoutes} from '../components/modules/AuthenticationModule/AuthenticationRoutes.js'
import {ShopsRoutes} from '../components/modules/ShopsModule/ShopsRoutes.js'
import {WarehouseManagementRoutes} from '../components/modules/WarehouseManagementModule/WarehouseManagementRoutes.js'
import {ProductsRoutes} from '../components/modules/ProductsModule/ProductsRoutes.js'
import {RefillProductsRoutes} from '../components/modules/RefillProductsModule/RefillProductsRoutes.js'
import {ProductsReportRoutes} from '../components/modules/ProductsReportModule/ProductsReportRoutes.js'
import {DisposalOrdersRoutes} from '../components/modules/DisposalOrdersModule/DisposalOrdersRoutes.js'
import {ImportOrdersRoutes} from '../components/modules/ImportOrdersModule/ImportOrdersRoutes.js'
import {CusPaymentsRoutes} from '../components/modules/CusPaymentsModule/CusPaymentsRoutes.js'
import {DownloadPluginsRoutes} from '../components/modules/DownloadPluginsModule/DownloadPluginsRoutes.js'
import {BalanceHistoryRoutes} from '../components/modules/BalanceHistoryModule/BalanceHistoryRoutes.js'
import {MyPaymentsRoutes} from '../components/modules/MyPaymentsModule/MyPaymentsRoutes.js'
import {UnidentifiedPaymentsRoutes} from '../components/modules/UnidentifiedPaymentsModule/UnidentifiedPaymentsRoutes.js'
import {UkrTreasuresModuleRoutes} from '../components/modules/UkrTreasuresModule/UkrTreasuresModuleRoutes.js'
import {ManageUsersModuleRoutes} from '../components/modules/ManageUsersModule/ManageUsersModuleRoutes.js'
import {RequestReturnRoutes} from '../components/modules/RequestReturnModule/RequestReturnRoutes.js'
import {RequestFundsRoutes} from '../components/modules/RequestFundsModule/RequestFundsRoutes.js'
import {PayPalCasesRoutes} from '../components/modules/PayPalCasesModule/PayPalCasesRoutes.js'
import {AccountsRoutes} from '../components/modules/AccountsModule/AccountsRoutes.js'
import {UsersRoutes} from '../components/modules/UsersModule/UsersRoutes.js'
import {EtsyListingsRoutes} from '../components/modules/EtsyListingsModule/EtsyListingsRoutes.js'
import {EtsyTransferRoutes} from '../components/modules/EtsyTransferModule/EtsyTransferRoutes.js'
import {CalculatorRoutes} from '../components/modules/CalculatorModule/CalculatorRoutes.js'
import {CalculatorEngravingRoutes} from '../components/modules/CalculatorEngravingModule/CalculatorEngravingRoutes.js'
import {EtsyRankRoutes} from '../components/modules/EtsyRankModule/EtsyRankRoutes.js'
import {CustomsInfoRoutes} from '../components/modules/CustomsInfoModule/CustomsInfoRoutes.js'
import {OrdersInboundRoutes} from '../components/modules/OrdersInboundModule/OrdersInboundRoutes.js'
import {ConsolidationRoutes} from '../components/modules/ConsolidationModule/ConsolidationRoutes.js'
import {FillClaimRoutes} from '../components/modules/FillClaimModule/FillClaimRoutes.js'
import {ParcelsRoutes} from '../components/modules/ParcelsModule/ParcelsRoutes.js'
import {DeliveryStatisticsRoutes} from '../components/modules/DeliveryStatisticsModule/DeliveryStatisticsRoutes.js'
// import {UnidentifiedReturnsRoutes} from '../components/modules/UnidentifiedReturnsModule/UnidentifiedReturnsRoutes.js'
import {ReturnGoodsRoutes} from '../components/modules/ReturnGoodsModule/ReturnGoodsRoutes.js'
import {ProfitReportRoutes} from '../components/modules/ProfitReportModule/ProfitReportRoutes.js'
import {ProfilesRoutes} from '../components/modules/ProfilesModule/ProfilesRoutes.js'
import {InvoicesRoutes} from '../components/modules/InvoicesModule/InvoicesRoutes.js'
import {ProformCategoryRoutes} from '../components/modules/ProformCategoryModule/ProformCategoryRoutes.js'
import {ProformDataRoutes} from '../components/modules/ProformDataModule/ProformDataRoutes.js'
import {NewPaymentsRoutes} from '../components/modules/NewPaymentsModule/NewPaymentsRoutes.js'
import {OtherShippersRoutes} from '../components/modules/OtherShippersModule/OtherShippersRoutes.js'
import {ReportCellsRoutes} from '../components/modules/ReportCellsModule/ReportCellsRoutes.js'
import {ProcessFBMReportRoutes} from '../components/modules/ProcessFBMReportModule/ProcessFBMReportRoutes.js'
import {ConsolidationOrdersRoutes} from '../components/modules/ConsolidationOrdersModule/ConsolidationOrdersRoutes.js'
import {GenerateReportPageRoutes} from '../components/modules/GenerateReportPageModule/GenerateReportPageRoutes.js'
import {GenerateReportFBAPageRoutes} from '../components/modules/GenerateReportFBAPageModule/GenerateReportFBAPageRoutes.js'
import {ConnectionsHistoryRoutes} from '../components/modules/ConnectionsHistoryModule/ConnectionsHistoryRoutes'
import {FastOrdersRoutes} from '../components/modules/FastOrdersModule/FastOrdersRoutes'
import {APCDelconRoutes} from '../components/modules/APCDelconModule/APCDelconRoutes.js'
import {ExpensesRoutes} from '../components/modules/ExpensesModule/ExpensesRoutes.js'
import {OtherIncomeRoutes} from '../components/modules/OtherIncomeModule/OtherIncomeRoutes.js'
import {MainSettingsRoutes} from '../components/modules/MainSettingsModule/MainSettingsRoutes.js'
import {ConsolidationUnionRoutes} from '../components/modules/ConsolidationUnionModule/ConsolidationUnionRoutes.js'
import {OrderPriceRoutes} from '../components/modules/OrderPriceModule/OrderPriceRoutes.js'
import {ZonePriceRoutes} from '../components/modules/ZonePriceModule/ZonePriceRoutes.js'
import {ShippingCompanyRoutes} from '../components/modules/ShippingCompanyModule/ShippingCompanyRoutes.js'
import {TrackingPayPalRoutes} from '../components/modules/TrackingPayPalModule/TrackingPayPalRoutes.js'
import {AccountBanksRoutes} from '../components/modules/AccountBanksModule/AccountBanksRoutes.js'
import {ShippingCostRoutes} from '../components/modules/ShippingCostModule/ShippingCostRoutes.js'
import {PackagingTypesRoutes} from '../components/modules/PackagingTypesModule/PackagingTypesRoutes.js'
import {CellsRoutes} from '../components/modules/CellsModule/CellsRoutes.js'
import {OrderBinRoutes} from '../components/modules/OrderBinModule/OrderBinRoutes.js'
import {IOSSNumbersRoutes} from "../components/modules/IOSSNumbersModule/IOSSNumbersRoutes";
import {InsuranceSkladUsaRoutes} from "../components/modules/InsuranceSkladUsaModule/InsuranceSkladUsaRoutes";
import {InventoryRoutes} from '../components/modules/InventoryModule/InventoryRoutes.js'
import {ForbiddenGoodsRoutes} from '../components/modules/ForbiddenGoodsModule/ForbiddenGoodsRoutes.js'
import {ForbiddenKeywordsRoutes} from '../components/modules/ForbiddenKeywordsModule/ForbiddenKeywords.js'
import {ThermalPrintRoutes} from '../components/modules/ThermalPrintModule/ThermalPrintRoutes.js'
import {HSCodesRoutes} from '../components/modules/HSCodesModule/HSCodesRoutes.js'
import {GetFileRoutes} from '../components/modules/GetFileModule/GetFileRoutes.js'
import {ShippingInsurancesRoutes} from '../components/modules/ShippingInsurancesModule/ShippingInsurancesRoutes.js'
import {GenerateFileRoutes} from '../components/modules/GenerateFileModule/GenerateFileRoutes.js'
import {TrackTestRoutes} from '../components/modules/TrackTestModule/TrackTestRoutes.js'
import {ConsolidationUsersRoutes} from '../components/modules/ConsolidationUsersModule/ConsolidationUsersRoutes.js'
import {AuthTokensRoutes} from '../components/modules/AuthTokensModule/AuthTokensRoutes.js'
import {ScanBarcodeFBMRoutes} from "../components/modules/ScanBarcodeFBMModule/ScanBarcodeFBMRoutes";
import {MultiSearchRoutes} from '../components/modules/MultiSearchModule/MultiSearchRoutes.js'
import {SearchRoutes} from '../components/modules/SearchModule/SearchRoutes.js'
import {ScanBarcodeRoutes} from '../components/modules/ScanBarcodeModule/ScanBarcodeRoutes.js'
import {NegativeBalanceRoutes} from '../components/modules/NegativeBalanceModule/NegativeBalanceRoutes.js'
import {ConsolidationMeestRoutes} from '../components/modules/ConsolidationMeestModule/ConsolidationMeestRoutes.js'
import {FreshChatRoutes} from '../components/modules/FreshChatModule/FreshChatRoutes.js'
import {ChatRoutes} from '../components/modules/ChatModule/ChatRoutes.js'
import {HeartysanRoutes} from '../components/modules/HeartysanModule/HeartysanRoutes.js'
import {HeartysanCategoryRoutes} from '../components/modules/HeartysanCategoryModule/HeartysanCategoryRoutes.js'
import {HeartysanUsersRoutes} from '../components/modules/HeartysanUsersModule/HeartysanUsersRoutes.js'
import {HeartysanChatRoutes} from '../components/modules/HeartysanChatModule/HeartysanChatRoutes.js'
import {HeartysanEtsyRoutes} from '../components/modules/HeartysanEtsyModule/HeartysanEtsyRoutes.js'
import {ErrorLogsRoutes} from '../components/modules/ErrorLogsModule/ErrorLogsRoutes.js'
import {ErrorLogsBackendRoutes} from '../components/modules/ErrorLogsBackendModule/ErrorLogsBackendRoutes.js'
import {ShippingReportRoutes} from '../components/modules/ShippingReportModule/ShippingReportRoutes.js'
import {InactiveFBMUsersRoutes} from '../components/modules/InactiveFBMUsersModule/InactiveFBMUsersRoutes.js'
import {TransactionExportRoutes} from '../components/modules/TransactionExportModule/TransactionExportRoutes.js'
import {ExportRequestsRoutes} from '../components/modules/ExportRequestsModule/ExportRequestsRoutes.js'
import {OrderSizeRoutes} from '../components/modules/OrderSizeModule/OrderSizeRoutes.js'
import {PromoCodeRoutes} from '../components/modules/PromoCodeModule/PromoCodeRoutes.js'
import {EasypostInvoicesRoutes} from '../components/modules/EasypostInvoicesModule/EasypostInvoicesRoutes.js'
import {MarketplaceProductsRoutes} from '../components/modules/MarketplaceProductsModule/MarketplaceProductsRoutes.js'
import {MarketplaceReviewsRoutes} from "../components/modules/MarketplaceReviewsModule/MarketplaceReviewsRoutes";
import {MarketplaceSettingsRoutes} from "../components/modules/MarketplaceSettingsModule/MarketplaceSettingsRoutes";
import {SynchronizeShopifyRoutes} from "../components/modules/SynchronizeShopifyModule/SynchronizeShopifyRoutes";
import {MarketplaceRatesRoutes} from "../components/modules/MarketplaceRatesModule/MarketplaceRatesRoutes";
import {BalancePayoneerHistoryRoutes} from "../components/modules/BalancePayoneerHistoryModule/BalancePayoneerHistoryRoutes";
import {NewPaymentsPayoneerRoutes} from "../components/modules/NewPaymentsPayoneerModule/NewPaymentsPayoneerRoutes";
import {IE559ReportRoutes} from "../components/modules/IE559ReportModule/IE559ReportRoutes.js";
import {ExpressPoshtaReportRoutes} from "../components/modules/ExpressPoshtaReportModule/ExpressPoshtaReportRoutes";
import {CalculatorSettingsRoutes} from "../components/modules/CalculatorSettingsModule/CalculatorSettingsRoutes";
import {OrdersMeestRoutes} from "../components/modules/OrdersMeestModule/OrdersMeestRoutes";
import {ExpressFastOrdersRoutes} from "../components/modules/ExpressFastOrdersModule/ExpressFastOrdersRoutes";
import {ExpressProblemRoutes} from "../components/modules/ExpressProblemModule/ExpressProblemRoutes";

import middlewarePipeline from "./middlewarePipeline";
import {PERMISSIONS} from "@/staticData/permissionsStatic";
import permissions from "@/router/middleware/permissions";

// *************  End Module Routes Include ************ //

const routes = [

  {
    path: '/',
    redirect: routerPaths.dashboard,
  },
  {
    path: routerPaths.dashboard,
    component: Dashboard,
    children: DashboardRoutes,
    meta: {
      breadcrumbs: {},

      moduleKey: 'dashboard',
      pageTitle: 'menu_dashboard'
    },
  },

  {
    path: routerPaths.errorPage504,
    component: Error504Module,
    meta:{
      pageTitle: 'common_notFound'
    }
  },

  {
    path: routerPaths.transactions,
    redirect: routerPaths.cusPayments,
  },

  {
    path: routerPaths.calculator,
    component: CalculatorModule,
    children: CalculatorRoutes,
  },

  {
    path: routerPaths.calculatorEngraving,
    component: CalculatorEngravingModule,
    children: CalculatorEngravingRoutes,
  },

  {
    path: routerPaths.statisticsDeliveryStatistics,
    component: DeliveryStatisticsModule,
    children: DeliveryStatisticsRoutes,
  },

  {
    path: routerPaths.graphicsStatistic,
    component: GraphicsStatisticModule,
    meta: {
      breadcrumbs: {
        key: [
          'statistics',
          'graphicsStatistic',
        ],
        items: {
          'statistics': {
            name: 'menu_statistics',
            link: routerPaths.graphicsStatistic,
          },
          'graphicsStatistic': {
            name: 'menu_graphicsStatistic',
            link: routerPaths.graphicsStatistic,
          },
        },
      },

      middleware: [permissions],
      permissions: [PERMISSIONS.USER_BALANCE_GRAPHIC_STATISTIC],

      pageTitle: 'menu_graphicsStatistic'
    },
  },

  {
    path: routerPaths.notificationsList,
    component: NotificationListModule,
    meta: {
      breadcrumbs: {
        key: [
          'notifications',
        ],
        items: {
          'notifications': {
            name: 'menu_notifications',
            link: routerPaths.graphicsStatistic,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.USER_BALANCE_GRAPHIC_STATISTIC],

      pageTitle: 'menu_notifications'
    },
  },

  {
    path: routerPaths.statisticsEtsyRank,
    component: EtsyRankModule,
    children: EtsyRankRoutes,
  },

  {
    path: routerPaths.cusPayments,
    component: CusPaymentsModule,
    children: CusPaymentsRoutes,
  },


  {
    path: routerPaths.ordersAndDispatches,
    component: ExpressOrdersModule,
    redirect: routerPaths.expressOrders,
    children: ExpressRoutes,
  },

  {
    path: routerPaths.ordersConsolidation,
    component: ConsolidationModule,
    children: ConsolidationRoutes,
  },

  {
    path: routerPaths.products,
    component: ProductsModule,
    children: ProductsRoutes,
  },

  {
    path: routerPaths.refillProducts,
    component: RefillProductsModule,
    children: RefillProductsRoutes,
  },

  {
    path: routerPaths.warehouseProductsReport,
    component: ProductsReportModule,
    children: ProductsReportRoutes,
  },

  {
    path: routerPaths.warehouseDisposalOrders,
    component: DisposalOrdersModule,
    children: DisposalOrdersRoutes,
  },

  {
    path: routerPaths.ordersFBM,
    component: OrdersFBMModule,
    children: OrdersFBMRoutes,
  },

  {
    path: routerPaths.ordersFBA,
    component: OrdersFBAModule,
    children: OrdersFBARoutes,
  },

  {
    path: routerPaths.ordersShipmentWarehouse,
    component: OrdersInboundModule,
    children: OrdersInboundRoutes,
  },

  {
    path: routerPaths.ukrposhta,
    component: UkrposhtaModule,
    children: UkrposhtaRoutes,
  },

  {
    path: routerPaths.belposhta,
    component: BelposhtaModule,
    children: BelposhtaRoutes,
  },

  {
    path: routerPaths.cdek,
    component: CDEKModule,
    children: CDEKRoutes,
  },

  {
    path: routerPaths.novaPoshta,
    component: NovaPoshtaModule,
    children: NovaPoshtaRoutes,
  },

  {
    path: routerPaths.importOrders,
    component: ImportOrdersModule,
    children: ImportOrdersRoutes,
  },

  {
    path: routerPaths.financeMyPayments,
    component: MyPaymentsModule,
    children: MyPaymentsRoutes,
  },

  {
    path: routerPaths.financeUnidentifiedPayments,
    component: UnidentifiedPaymentsModule,
    children: UnidentifiedPaymentsRoutes,
  },

  {
    path: routerPaths.financeUkrTreasuresLink,
    component: UkrTreasuresModule,
    children: UkrTreasuresModuleRoutes,
  },

  {
    path: routerPaths.financeRequestReturn,
    component: RequestReturnModule,
    children: RequestReturnRoutes,
  },

  {
    path: routerPaths.financePayPalCases,
    component: PayPalCasesModule,
    children: PayPalCasesRoutes,
  },

  {
    path: routerPaths.financeRequestFunds,
    component: RequestFundsModule,
    children: RequestFundsRoutes,
  },

  {
    path: routerPaths.financeRequestFunds,
    component: FillClaimModule,
    children: FillClaimRoutes,
  },

  {
    path: routerPaths.financeInvoices,
    component: InvoicesModule,
    children: InvoicesRoutes,
  },

  {
    path: routerPaths.problemsParcels,
    component: ParcelsModule,
    children: ParcelsRoutes,
  },

  // {
  //   path: routerPaths.problemsUnidentifiedReturns,
  //   component: UnidentifiedReturnsModule,
  //   children: UnidentifiedReturnsRoutes,
  // },

  {
    path: routerPaths.incomeExpensesProfitReport,
    component: ProfitReportModule,
    children: ProfitReportRoutes,
  },

  {
    path: routerPaths.expenses,
    component: ExpensesModule,
    children: ExpensesRoutes,
  },

  {
    path: routerPaths.otherIncome,
    component: OtherIncomeModule,
    children: OtherIncomeRoutes,
  },

  {
    path: routerPaths.problemsReturnsModule,
    component: ReturnGoodsModule,
    children: ReturnGoodsRoutes,
  },

  {
    path: routerPaths.mainSettingsManageUsersLink,
    component: ManageUsersModule,
    children: ManageUsersModuleRoutes,
  },


  {
    path: routerPaths.downloadPlugins,
    component: DownloadPluginsModule,
    children: DownloadPluginsRoutes,
  },



  {
    path: routerPaths.mainSettingsEtsyListings,
    component: EtsyListingsModule,
    children: EtsyListingsRoutes,
  },

  {
    path: routerPaths.mainSettingsEtsyTransfer,
    component: EtsyTransferModule,
    children: EtsyTransferRoutes,
  },


  {
    path: routerPaths.settings,
    component: SettingsModule,
    redirect: routerPaths.settingsUsers,
    children: SettingsRoutes,
  },

  {
    path: routerPaths.mainSettingsEdit,
    component: MainSettingsModule,
    redirect: routerPaths.mainSettingsEdit,
    children: MainSettingsRoutes,
  },

  {
    path: routerPaths.mainSettingsProformCategory,
    component: ProformCategoryModule,
    children: ProformCategoryRoutes,
  },

  {
    path: routerPaths.mainSettingsProformData,
    component: ProformDataModule,
    children: ProformDataRoutes,
  },

  {
    path: routerPaths.mainSettingsHSCodes,
    component: HSCodesModule,
    children: HSCodesRoutes,
  },

  {
    path: routerPaths.mainSettingsMyProfile,
    component: ProfileModule,
    meta: {
      breadcrumbs: {
        key: [
          // 'setting',
          'myProfile',
        ],
        items: {
          // 'setting': {
          //   name: 'menu_settings',
          //   link: routerPaths.settings,
          // },
          'myProfile': {
            name: 'menu_myProfile',
            link: '',
          },
        },
      },

      pageTitle: 'menu_myProfile'
    },
  },


  {
    path: routerPaths.mainSettingsCustomsInformation,
    component: CustomsInfoModule,
    redirect: routerPaths.mainSettingsCustomsInformation,
    children: CustomsInfoRoutes,
  },


  {
    path: routerPaths.mainSettingsNotifications,
    component: NotificationModule,
    meta: {
      breadcrumbs: {
        key: [
          'setting',
          'mainSettingsNotifications',
        ],
        items: {
          'setting': {
            name: 'menu_settings',
            link: routerPaths.mainSettingsNotifications,
          },
          'mainSettingsNotifications': {
            name: 'menu_notifications',
            link: routerPaths.mainSettingsNotifications,
          },
        },
      },

      // middleware: [permissions],
      // permissions: [PERMISSIONS.NOTIFICATION_GENERAL],

      pageTitle: 'menu_notifications'
    },
  },




  {
    path: routerPaths.balanceHistory,
    component: BalanceHistoryModule,
    children: BalanceHistoryRoutes,
  },

  {
    path: routerPaths.mainSettingsUsers,
    component: UsersModule,
    children: UsersRoutes,
  },

  {
    path: routerPaths.mainSettingsShops,
    component: ShopsModule,
    children: ShopsRoutes,
  },

  {
    path: routerPaths.accounts,
    component: AccountsModule,
    children: AccountsRoutes,
  },

  {
    path: routerPaths.mainSettingsWarehouseManagement,
    component: WarehouseManagementModule,
    children: WarehouseManagementRoutes,
  },

  {
    path: routerPaths.mainSettingsProfilesLink,
    component: ProfilesModule,
    children: ProfilesRoutes,
  },

  {
    path: routerPaths.financeNewPayment,
    component: NewPaymentsModule,
    children: NewPaymentsRoutes,
  },

  {
    path: routerPaths.financeReportCells,
    component: ReportCellsModule,
    children: ReportCellsRoutes,
  },

  {
    path: routerPaths.mainSettingsOtherShippers,
    component: OtherShippersModule,
    children: OtherShippersRoutes,
  },

  {
    path: routerPaths.reportsProcessFBMReport,
    component: ProcessFBMReportModule,
    children: ProcessFBMReportRoutes,
  },

  {
    path: routerPaths.reportsConsolidationOrders,
    component: ConsolidationOrdersModule,
    children: ConsolidationOrdersRoutes,
  },

  {
    path: routerPaths.reportsGenerateReportPage,
    component: GenerateReportPageModule,
    children: GenerateReportPageRoutes,
  },

  {
    path: routerPaths.reportsGenerateReportFBAPage,
    component: GenerateReportFBAPageModule,
    children: GenerateReportFBAPageRoutes,
  },

  {
    path: routerPaths.reportsConnectionsHistory,
    component: ConnectionsHistoryModule,
    children: ConnectionsHistoryRoutes,
  },

  {
    path: routerPaths.reportsFastOrders,
    component: FastOrdersModule,
    children: FastOrdersRoutes,
  },

  {
    path: routerPaths.reportsAPCDelcon,
    component: APCDelconModule,
    children: APCDelconRoutes,
  },

  {
    path: routerPaths.reportsConsolidation,
    component: ConsolidationUnionModule,
    children: ConsolidationUnionRoutes,
  },

  {
    path: routerPaths.mainSettingsOrderPrice,
    component: OrderPriceModule,
    children: OrderPriceRoutes,
  },

  {
    path: routerPaths.mainSettingsZonePrice,
    component: ZonePriceModule,
    children: ZonePriceRoutes,
  },

  {
    path: routerPaths.shippingCompany,
    component: ShippingCompanyModule,
    children: ShippingCompanyRoutes,
  },

  {
    path: routerPaths.trackingForPayPal,
    component: TrackingPayPalModule,
    children: TrackingPayPalRoutes,
  },

  {
    path: routerPaths.accountTypes,
    component: AccountBanksModule,
    children: AccountBanksRoutes,
  },

  {
    path: routerPaths.mainSettingsShippingCost,
    component: ShippingCostModule,
    children: ShippingCostRoutes,
  },


  {
    path: routerPaths.mainSettingsPackagingTypes,
    component: PackagingTypesModule,
    children: PackagingTypesRoutes,
  },

  {
    path: routerPaths.mainSettingsCells,
    component: CellsModule,
    children: CellsRoutes,
  },

  {
    path: routerPaths.settingsOrderBin,
    component: OrderBinModule,
    children: OrderBinRoutes,
  },

  {
    path: routerPaths.mainSettingsIOSSNumbers,
    component: IOSSNumbersModule,
    children: IOSSNumbersRoutes,
  },

  {
    path: routerPaths.mainSettingsInsuranceSkladUsa,
    component: InsuranceSkladUsaModule,
    children: InsuranceSkladUsaRoutes,
  },

  {
    path: routerPaths.mainSettingsInventory,
    component: InventoryModule,
    children: InventoryRoutes,
  },

  {
    path: routerPaths.thermalPrint,
    component: ThermalPrintModule,
    children: ThermalPrintRoutes,
  },

  {
    path: routerPaths.forbiddenGoods,
    component: ForbiddenGoodsModule,
    children: ForbiddenGoodsRoutes,
  },

  {
    path: routerPaths.forbiddenKeywords,
    component: ForbiddenKeywordsModule,
    children: ForbiddenKeywordsRoutes,
  },

  {
    path: routerPaths.trackTest,
    component: TrackTestModule,
    children: TrackTestRoutes,
  },

  {
    path: routerPaths.consolidationUsers,
    component: ConsolidationUsersModule,
    children: ConsolidationUsersRoutes,
  },

  {
    path: routerPaths.authTokens,
    component: AuthTokensModule,
    children: AuthTokensRoutes,
  },

  {
    path: routerPaths.scanBarcodeFBM,
    component: ScanBarcodeFBMModule,
    children: ScanBarcodeFBMRoutes,
  },

  {
    path: routerPaths.multiSearch,
    component: MultiSearchModule,
    children: MultiSearchRoutes,
  },

  {
    path: routerPaths.search,
    component: SearchModule,
    children: SearchRoutes,
  },

  {
    path: routerPaths.negativeBalance,
    component: NegativeBalanceModule,
    children: NegativeBalanceRoutes,
  },

  {
    path: routerPaths.reportsConsolidationMeest,
    component: ConsolidationMeestModule,
    children: ConsolidationMeestRoutes,
  },

  {
    path: routerPaths.mainSettingsFreshChat,
    component: FreshChatModule,
    children: FreshChatRoutes,
  },

  {
    path: routerPaths.chat,
    component: ChatModule,
    children: ChatRoutes,
  },

  {
    path: routerPaths.heartysan,
    component: HeartysanModule,
    children: HeartysanRoutes,
  },

  {
    path: routerPaths.heartysanCategory,
    component: HeartysanCategoryModule,
    children: HeartysanCategoryRoutes,
  },

  {
    path: routerPaths.heartysanUsers,
    component: HeartysanUsersModule,
    children: HeartysanUsersRoutes,
  },

  {
    path: routerPaths.heartysanChat,
    component: HeartysanChatModule,
    children: HeartysanChatRoutes,
  },

  {
    path: routerPaths.heartysanChat,
    component: HeartysanEtsyModule,
    children: HeartysanEtsyRoutes,
  },

  {
    path: routerPaths.errorLogs,
    component: ErrorLogsModule,
    children: ErrorLogsRoutes,
  },

  {
    path: routerPaths.errorLogs,
    component: ErrorLogsBackendModule,
    children: ErrorLogsBackendRoutes,
  },

  {
    path: routerPaths.shippingReport,
    component: ShippingReportModule,
    children: ShippingReportRoutes,
  },

  {
    path: routerPaths.inactiveFBMUsers,
    component: InactiveFBMUsersModule,
    children: InactiveFBMUsersRoutes,
  },

  {
    path: routerPaths.transactionExport,
    component: TransactionExportModule,
    children: TransactionExportRoutes,
  },

  {
    path: routerPaths.shippingInsurances,
    component: ShippingInsurancesModule,
    children: ShippingInsurancesRoutes,
  },

  {
    path: routerPaths.authentication,
    component: AuthenticationModule,
    // redirect: routerPaths.authenticationGoogle,
    children: AuthenticationRoutes,
  },
  {
    path: routerPaths.scanBarcode,
    component: ScanBarcodeModule,
    children: ScanBarcodeRoutes,
  },
  {
    path: routerPaths.exportRequests,
    component: ExportRequestsModule,
    children: ExportRequestsRoutes,
  },
  {
    path: routerPaths.mainSettingsOrderSize,
    component: OrderSizeModule,
    children: OrderSizeRoutes,
  },
  {
    path: routerPaths.mainSettingsPromoCode,
    component: PromoCodeModule,
    children: PromoCodeRoutes,
  },
  {
    path: routerPaths.mainSettingsEasypostInvoices,
    component: EasypostInvoicesModule,
    children: EasypostInvoicesRoutes,
  },
  {
    path: routerPaths.marketplaceProducts,
    component: MarketplaceProductsModule,
    children: MarketplaceProductsRoutes,
  },
  {
    path: routerPaths.marketplaceReviews,
    component: MarketplaceReviewsModule,
    children: MarketplaceReviewsRoutes,
  },
  {
    path: routerPaths.marketplaceSettingsEditItem,
    component: MarketplaceSettingsModule,
    children: MarketplaceSettingsRoutes,
  },
  {
    path: routerPaths.ordersMeestReturns,
    component: OrdersMeestModule,
    children: OrdersMeestRoutes,
  },
  {
    path: routerPaths.reportsExpressFastOrders,
    component: ExpressFastOrdersModule,
    children: ExpressFastOrdersRoutes,
  },
  {
    path: routerPaths.reportsExpressProblem,
    component: ExpressProblemModule,
    children: ExpressProblemRoutes,
  },
  {
    path: routerPaths.marketplaceRates,
    component: MarketplaceRatesModule,
    children: MarketplaceRatesRoutes,
  },
  {
    path: routerPaths.balancePayoneerHistory,
    component: BalancePayoneerHistoryModule,
    children: BalancePayoneerHistoryRoutes,
  },
  {
    path: routerPaths.generatePdfFileId,
    component: GenerateFileModule,
    children: GenerateFileRoutes,
    meta: {
      layout: {
        name: 'file',
      },
    },
  },
  {
    path: routerPaths.getFile,
    component: GetFileModule,
    children: GetFileRoutes,
    meta: {
      layout: {
        name: 'file',
      },
    },
  },

  {
    path: routerPaths.auth,
    component: Auth,
    redirect: routerPaths.login,
    children: AuthRoutes,
    meta: {
      layout: {
        name: 'auth',
      },
    },
  },

  {
    path: routerPaths.authAdmin,
    component: Auth,
    redirect: routerPaths.loginAdmin,
    children: AuthRoutes,
    meta: {
      layout: {
        name: 'auth',
      },
    },
  },

  {
    path: routerPaths.signUp,
    component: Registration,
    meta: {
      layout: {
        name: 'auth',
      },
      pageTitle: 'registration_title'
    },
  },
  {
    path: routerPaths.shopifyInfoPage,
    component: SynchronizeShopifyModule,
    children: SynchronizeShopifyRoutes,
    meta: {
      layout: {
        name: 'not-auth',
      },
    },
  },

  {
    path: routerPaths.financeNewPaymentsPayoneer,
    component: NewPaymentsPayoneerModule,
    children: NewPaymentsPayoneerRoutes,
  },

  {
    path: routerPaths.expressPoshtaReport,
    component: ExpressPoshtaReportModule,
    children: ExpressPoshtaReportRoutes,
  },

  {
    path: routerPaths.mainSettingsCalculatorSettings,
    component: CalculatorSettingsModule,
    children: CalculatorSettingsRoutes,
  },

  {
    path: routerPaths.IE559Report,
    component: IE559ReportModule,
    children: IE559ReportRoutes,
  },

  {
    path: routerPaths.synchronizeShop,
    component: SynchronizeShop,
    meta:{
      pageTitle: 'common_synchronizing'
    }
  },

  {
    path: routerPaths.shopifyConnection,
    component: ShopifyConnection,
    meta:{
      pageTitle: 'common_synchronizing'
    }
  },

  {
    path: routerPaths.historyOfChanges,
    component: HistoryOfChangesComponent,
    meta:{
      pageTitle: 'common_hystory'
    }
  },

  {
    path: routerPaths.errorPage503,
    component: Error503Module,
    meta:{
      pageTitle: 'common_notFound'
    }
  },


  {
    path: '*',
    component: NotFoundModule,
    meta:{
      pageTitle: 'common_notFound'
    }
  },

]

export const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() { //fix scroll to top after
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  // (to.path.indexOf(routerPaths.auth) > -1 && to.path.indexOf(routerPaths.signUp) > -1) &&

  store.commit('SET_ROUTER_HISTORY', from.fullPath)

  function ifShopifyInfoPage() {
    return to.path.indexOf(routerPaths.shopifyInfoPage) > -1
  }

  function ifLoginPage() {
    return to.path.indexOf(routerPaths.auth) > -1 || to.path.indexOf(routerPaths.signUp) > -1
  }

  function ifNotLoginPage() {
    return to.path.indexOf(routerPaths.auth) < 0 && to.path.indexOf(routerPaths.signUp) < 0
  }

  // function ifSystemPages() {
  //   return to.path.indexOf('/system/') > -1
  // }

  checkMenu()

  if (ifShopifyInfoPage()) {
    return next()
  }

  if (ifLoginPage() && localStorage.getItem("token")) {
    next(routerPaths.dashboard)
  }

  if (window.localStorage.token === undefined && ifNotLoginPage()){
    next(routerPaths.auth)
  }

  // if( Object.prototype.hasOwnProperty.call(store.getters.getCurrentUserRights, "role") &&
  //   ifSystemPages() &&
  //   to.path.indexOf('users') === -1 &&
  //   store.getters.getCurrentUserRights.role.name !== store.getters.GET_DEVELOPER_ROLE
  // ) {
  //   next(routerPaths.dashboard)
  // }

  /**
   * Middleware
   */

  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store,
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })

  // else next()
})


function checkMenu() {
  // MENU HIDE WHEN CHANGE ROUTE
  if(window.innerWidth < 1600 && window.innerWidth > 680) {
    let sideBar = document.getElementById('side-bar')
    if(sideBar && sideBar.classList.contains('open'))
      sideBar.classList.remove('open')
    if(sideBar && sideBar.classList.contains('showMobile'))
      sideBar.classList.remove('showMobile')
  }

  // MOBILE MENU HIDE WHEN CHANGE ROUTE
  if(window.innerWidth < 680) {
    store.commit('SET_MOBILE_MENU', false)
  }
}
